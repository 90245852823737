<template>
  <v-list-group>
    <template v-slot:activator="{ props }">
      <v-list-item
        v-bind="props"
        :prepend-icon="icon"
        :title="title"
      >
      </v-list-item>
    </template>
    <slot />
  </v-list-group>
</template>

<script>
export default {
  name: 'AdminNavMenuGroup',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.v-list-group__items {
  --intent-padding: 0;
}
</style>
