<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/store/auth';

const { operator, authenticated, organizations, shops } = storeToRefs(useAuthStore());
const { logout } = useAuthStore();

const data = ref({
  title: 'Merci Admin',
  drawer: true,
  loading: false,
});

const currentShopId = ref(operator.value?.current_shop_id);
const currentOrganizationId = ref(operator.value?.current_organization_id);

watch(currentShopId, async value => {
  await $api.switchShop(value || 0);
  reloadNuxtApp({ ttl: 0 });
});

watch(currentOrganizationId, async value => {
  await $api.switchOrganization(value || 0);
  reloadNuxtApp({ ttl: 0 });
});

watch(operator, async value => {
  if (value) {
    currentShopId.value = value.current_shop_id;
    currentOrganizationId.value = value.current_organization_id;
  }
});
</script>
<template>
  <admin-navigation-drawer
    v-if="authenticated"
    v-model:drawer="data.drawer"
  />
  <v-app-bar
    v-if="authenticated"
    flat
  >
    <v-app-bar-nav-icon
      v-if="!data.drawer"
      variant="text"
      @click.stop="data.drawer = !data.drawer"
    >
    </v-app-bar-nav-icon>

    <v-spacer />

    <template v-slot:append>
      <v-select
        v-model="currentOrganizationId"
        v-if="operator?.role === 'system_manager'"
        :items="organizations"
        item-title="name"
        item-value="id"
        :label="$t('general.organization')"
        variant="outlined"
        density="compact"
        hide-details="auto"
        class="organization-selection"
        rounded
      />
      <v-select
        v-model="currentShopId"
        :items="shops"
        item-title="name"
        item-value="id"
        :label="$t('general.restaurant')"
        class="shop-selection"
        variant="outlined"
        density="compact"
        hide-details="auto"
        rounded
      />
      <v-menu
        offset-y
        left
      >
        <template #activator="{ props }">
          <v-badge
            bottom
            color="success"
            overlap
            offset-y="24"
            class="ms-5"
            dot
            v-bind="props"
          >
            <v-btn
              icon
              x-large
            >
              <v-avatar
                size="40px"
                v-bind="props"
              >
                <v-img src="/images/default-profile-icon.png" />
              </v-avatar>
            </v-btn>
          </v-badge>
        </template>
        <v-list>
          <div class="pb-3 pt-2">
            <div
              class="d-inline-flex flex-column justify-center ms-3"
              style="vertical-align: middle"
            >
              <span class="font-weight-bold"> {{ operator?.name }} </span>
              <small class="text-medium-emphasis">
                {{ $t(`enums.roles.${operator?.role}`) }}
              </small>
            </div>
          </div>

          <v-divider />
          <!--
          <v-list-item
            link
            prepend-icon="mdi-account-circle"
            title="Profile"
          >
          </v-list-item> -->
          <v-list-item
            link
            prepend-icon="mdi-logout-variant"
            @click="logout()"
            title="ログアウト"
          >
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>
  <merci-confirm ref="confirm" />
</template>
<style lang="scss" scoped>
.organization-selection {
  width: 250px;
  margin-right: 5px;
}

.shop-selection {
  width: 250px;
}
</style>
