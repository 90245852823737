<template>
  <v-list-subheader>
    <span class="title-wrapper">
      <span>{{ title }}</span>
    </span>
  </v-list-subheader>
</template>

<script>
export default {
  name: 'AdminNavMenuSectionTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
