<template>
  <v-list-item
    :title="title"
    active-class="bg-gradient-primary white--text"
    density="compact"
    :active="active()"
    :to="to"
  >
    <template v-slot:prepend>
      <v-icon
        v-if="icon"
        :color="color"
      >
        {{ icon }}
      </v-icon>
    </template>
  </v-list-item>
</template>

<script>
export default {
  name: 'AdminNavMenuLink',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: undefined,
    },
    color: {
      type: String,
      default: 'black',
    },
    to: {
      type: String,
      default: '',
    },
  },

  methods: {
    active() {
      return this.$route.path.startsWith(this.to);
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-gradient-primary {
  block-size: 2.625rem !important;
  border-end-end-radius: 3.125rem !important;
  border-end-start-radius: 0 !important;
  border-start-end-radius: 3.125rem !important;
  border-start-start-radius: 0 !important;
  background: linear-gradient(-72.47deg, #ffb400, #ffb400 76%);
  color: white;
  .v-icon {
    color: white !important;
  }
}

.v-list-item {
  block-size: 2.625rem !important;
  border-end-end-radius: 3.125rem !important;
  border-end-start-radius: 0 !important;
  border-start-end-radius: 3.125rem !important;
  border-start-start-radius: 0 !important;
}
</style>
