<template>
  <v-navigation-drawer
    v-model="setDrawer"
    floating
    :mobile-breakpoint="1300"
    width="300"
    location="start"
    class="layout-vertical-nav"
    elevation="3"
  >
    <div class="nav-header">
      <nuxt-link
        to="/"
        exact
        class="d-flex align-center text-decoration-none"
      >
        <h2 class="app-title text-primary">MERCI管理画面</h2>
      </nuxt-link>
    </div>

    <v-list
      shaped
      class="vertical-nav-menu-items pr-5"
      density="comfortable"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
      >
        <admin-nav-menu-section-title
          v-if="item.header && item.displayable"
          :title="item.header"
        />
        <admin-nav-menu-group
          v-if="item.displayable && item.lists"
          :title="item.title"
          :icon="item.icon"
          :color="item.color ? item.color : 'black'"
          class="mt-1"
        >
          <admin-nav-menu-link
            v-for="page in filteredItems(item.lists)"
            :key="page.title"
            :title="page.title"
            :to="page.to"
            :color="item.color ? item.color : 'black'"
            class="mt-1"
          />
        </admin-nav-menu-group>
        <admin-nav-menu-link
          v-else-if="item.displayable && item.title"
          :key="item.title"
          :title="item.title"
          :to="item.to"
          :color="item.color ? item.color : 'black'"
          :icon="item.icon"
          class="mt-1"
        />
      </div>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { useAuthStore } from '~/store/auth';

const { operator } = storeToRefs(useAuthStore());
const operatorRole = operator.value?.role;
export default {
  name: 'AdminNavigationDrawer',
  props: {
    drawer: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      title: 'Merci Admin',
      mini: false,
    };
  },

  computed: {
    setDrawer: {
      get() {
        return this.drawer;
      },
      set(val) {
        return this.$emit('update:drawer', val);
      },
    },

    items() {
      return [
        {
          header: 'レポート',
          displayable: true,
        },
        {
          icon: 'mdi-align-horizontal-left',
          title: '全体',
          to: '/dashboard',
          displayable: operatorRole === 'system_manager' || operatorRole === 'organization_manager',
          color: 'red',
        },
        {
          icon: 'mdi-circle-multiple-outline',
          title: 'ポイント',
          to: '/report-points',
          displayable: operatorRole === 'system_manager' || operatorRole === 'organization_manager',
          color: 'red',
        },
        {
          icon: 'mdi-calendar-badge',
          title: '予約分析',
          to: '/report-reservations',
          displayable: true,
          color: 'red',
        },
        {
          icon: 'mdi-receipt-text',
          title: '会計分析',
          to: '/report-receipts',
          displayable: true,
          color: 'red',
        },
        {
          icon: 'mdi-select-group',
          title: 'RFM分析',
          to: '/report-rfm',
          displayable: true,
          color: 'red',
        },
        {
          icon: 'mdi-mail',
          title: 'キャンペーン分析',
          to: '/campaign-reports',
          displayable: true,
          color: 'red',
        },
        {
          icon: 'mdi-arrow-decision-outline',
          title: 'アクセス分析',
          displayable: true,
          color: 'red',
          lists: [
            {
              title: 'ページビュー',
              to: '/statistics/page-views',
              displayable: true,
            },
            {
              title: '店舗予約',
              to: '/statistics/reservation-views',
              displayable: true,
            },
          ],
        },
        {
          header: '組織管理',
          displayable: true,
        },
        {
          icon: 'mdi-domain',
          title: '組織設定',
          to: '/organizations',
          displayable: operatorRole === 'system_manager',
        },
        {
          icon: 'mdi-store',
          title: '店舗一覧',
          to: '/shops',
          displayable: true,
        },
        {
          icon: 'mdi-account-tie',
          title: '管理者アカウント',
          to: '/operators',
          displayable: operatorRole === 'system_manager' || operatorRole === 'organization_manager',
        },
        {
          icon: 'mdi-account-group',
          title: '会員管理',
          to: '/users',
          displayable: true,
        },
        {
          icon: 'mdi-account-multiple',
          title: 'セグメント',
          to: '/user-segments',
          displayable: true,
        },
        {
          icon: 'mdi-chart-bar',
          title: 'キャンペーン',
          to: '/campaigns',
          displayable: true,
        },
        {
          icon: 'mdi-ticket-percent-outline',
          title: 'クーポン',
          to: '/coupons',
          displayable: true,
        },
        {
          icon: 'mdi-newspaper',
          title: 'お知らせ',
          to: '/news',
          displayable: true,
        },
        {
          header: '店舗管理',
          displayable: true,
        },
        {
          icon: 'mdi-calendar-badge',
          title: '予約',
          to: '/reservations',
          displayable: true,
        },
        {
          icon: 'mdi-food',
          title: 'メニュー',
          to: '/menu-items',
          displayable: true,
        },
        {
          icon: 'mdi-nutrition',
          title: 'ジャンル',
          to: '/genres',
          displayable: true,
        },
      ];
    },
  },

  methods: {
    goHome() {
      this.$router.push('/dashboard');
    },

    filteredItems(items) {
      if (items) {
        return items.filter(item => item.displayable);
      }

      return [];
    },
  },
};
</script>
<style lang="scss" scoped>
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

a {
  text-decoration: none;
}

.nav-header {
  align-items: center;
  padding: 20px;
}

.drawer-item {
  &--active {
    background: #e5e5e5;
  }

  &--default:hover {
    background: #f2f2f3;
  }
}
</style>
