<script lang="ts" setup>
import { useConfirmDialogStore } from '~/store/confirmDialog';
const confirmDialogStore = useConfirmDialogStore();
</script>

<template>
  <v-dialog
    v-model="confirmDialogStore.dialog"
    :max-width="confirmDialogStore.option.width"
    @keydown.esc="confirmDialogStore.cancel"
  >
    <v-card>
      <v-card-title
        :style="`background: #FFB400; color: white`"
      >
        <span>
          {{ confirmDialogStore.title }}
        </span>
      </v-card-title>
      <v-card-text
        v-show="!!confirmDialogStore.message"
        class="pa-4"
      >
        {{ confirmDialogStore.message }}
      </v-card-text>
      <slot name="body" />
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn
          color="primary darken-1"
          variant="text"
          @click="confirmDialogStore.agree"
        >
          {{ $t('general.ok') }}
        </v-btn>
        <v-btn
          color="grey"
          variant="text"
          @click="confirmDialogStore.cancel"
        >
          {{ $t('general.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
div {
  word-break: break-word;
}
</style>
